//import React, { useState } from "react";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HomeApp } from "./components/Home.component.js";
import { DniApp } from "./components/Dni.component";
import { MenuApp } from "./components/Menu.component";
import { TicketApp } from "./components/Ticket.component";
import { TicketList } from "./components/TicketList.component";
import { useSelector } from "react-redux";
import { selectApp } from "./redux/slices/appSlice";

function App() {
  const app = useSelector(selectApp);

  const renderSwitch = (app) => {
    switch (app) {
      case "HOME":
        return <HomeApp />;
      case "DNI":
        return <DniApp />;
      case "TICKETLIST":
        return <TicketList />;
      case "TICKET":
        return <TicketApp />;
      case "MENU":
        return <MenuApp />;
      default:
        return "#FFFFFF";
    }
  };

  return (
    <div
      className="bg-dark"
      // style={{
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      // }}
    >
      <Container fluid>
        <Row className="">
          <Col sm={3}></Col>
          <Col className="bg-dark">
            <Row className="bg-light p-1 mt-5">{renderSwitch(app)}</Row>
          </Col>
          <Col sm={3}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
