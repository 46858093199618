import http from "../http-common";

const getDni = (dni) => {
  return http.get(`/dni/${dni}`);
};

const registerDni = (data) => {
  return http.post("/dni", data);
};

const DniService = {
  getDni,
  registerDni,
};
export default DniService;
