import http from "../http-common";

const getTicket = (tk) => {
  return http.get(`/Ticket/${tk}`);
};

const getTicketForm = (form) => {
  return http.get(`/Ticket/${form.terminal}/${form.monto}/${form.fecha}/${form.hora}`);
};

const registerTicket = (data) => {
  return http.post("/Ticket", data);
};

const TicketService = {
  getTicket,
  getTicketForm,
  registerTicket,
};
export default TicketService;
