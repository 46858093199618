import axios from "axios";

export default axios.create({
  // baseURL: "https://localhost:44342/api/",
  baseURL: "http://loteriasdelperu.net:9056/api/",
  
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
