import React, { useState, useEffect } from "react";
import { Card, Button, ListGroup, Badge, Row, Col } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { menuRedirect } from "../redux/slices/appSlice";

import OpcionService from "../services/OpcionService";

import { useSelector } from "react-redux";
import { selectDni } from "../redux/slices/dniSlice";

//import { TicketElement } from "./Ticket.element";
import { OpcionElement } from "./Opcion.element";

export const TicketList = () => {
  const regDni = useSelector(selectDni);
  const dispatch = useDispatch();

  const returnMenu = () => {
    //console.log(opciones);
    dispatch(menuRedirect());
  };
  
  useEffect(() => {    
    RetrieveOpciones();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(opciones);
  });

  const [opciones, setOpciones] = useState([]);

  const RetrieveOpciones = () => {
    OpcionService.getOpciones(regDni.dni)
      .then((response) => {
        setOpciones(response.data);
        console.log(opciones);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card className="w-100">
      <Card.Body>
        <Card.Title>
          <h2>Lista de Opciones</h2>
        </Card.Title>

        <ListGroup>
          {opciones &&
            opciones.map((iTicket, index) => (
              <ListGroup.Item key={index}>
                <Row className="border border-secondary">
                  <Col
                    sm={3}
                    className="bg-info"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Badge variant="info">
                      {/* <h3>{index + 1}</h3> */}
                      <h3>NRO</h3>
                      <h3>{iTicket.id}</h3>
                    </Badge>
                  </Col>
                  <Col
                    className="bg-light"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <OpcionElement key={index} {...iTicket} {...index}></OpcionElement>
                  </Col>
                </Row>

                {/* <OpcionElement key={index} {...iTicket}></OpcionElement> */}
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Card.Body>
      <Button variant="primary" onClick={returnMenu} block>
        <strong>REGRESAR</strong>
      </Button>
    </Card>
  );
};
