import http from "../http-common";

const getOpciones = (dni) => {
  return http.get(`/opcion/${dni}`);
};

const registerOpcion = (data) => {
  return http.post("/opcion", data);
};

const registerOpcionForm = (data) => {
  return http.get(`/opcion/${data.dni}/${data.ticket_index}/${data.beneficencia}`);
};

const OpcionService = {
  getOpciones,
  registerOpcion,
  registerOpcionForm,
};
export default OpcionService;
