import { createSlice } from "@reduxjs/toolkit";

export const dniSlice = createSlice({
  name: "dni",
  initialState: {
    value: "",
  },
  reducers: {
    registerDni: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { registerDni } = dniSlice.actions;

export const selectDni = (state) => state.dni.value;

export default dniSlice.reducer;
