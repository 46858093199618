import React, { useState } from "react";
import { Card, Row, Col, Badge } from "react-bootstrap";

export const TicketElement = (props) => {
  const [ticket] = useState(props);
  return (
    <Card className="mt-5 mb-5 p-2 border border-info">
      <Row>
        <Col className="bg-info" sm={4}>
          <Badge variant="info">
            <h6>PUNTO DE VENTA:</h6>
          </Badge>
        </Col>
        <Col>
          <h6>{ticket.concesionario}</h6>
        </Col>
      </Row>
      <Row>
        <Col className="bg-info" sm={4}>
          <Badge variant="info">
            <h6>TERMINAL:</h6>
          </Badge>
        </Col>
        <Col>
          <h6>{ticket.serie}</h6>
        </Col>
      </Row>
      <Row>
        <Col className="bg-info" sm={4}>
          <Badge variant="info">
            <h6>TICKET:</h6>
          </Badge>
        </Col>
        <Col>
          <h6>{ticket.tk}</h6>
        </Col>
      </Row>
      <Row>
        <Col className="bg-info" sm={4}>
          <Badge variant="info">
            <h6>MONTO:</h6>
          </Badge>
        </Col>
        <Col>
          <h6>{ticket.monto}</h6>
        </Col>
      </Row>
      <Row>
        <Col className="bg-info" sm={4}>
          <Badge variant="info">
            <h6>FECHA:</h6>
          </Badge>
        </Col>
        <Col>
          <h6>{ticket.fecha}</h6>
        </Col>
      </Row>
    </Card>
  );
};
