import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    value: "HOME",
  },
  reducers: {
    homeRedirect: (state) => {
      state.value = "HOME";
    },
    dniRedirect: (state) => {
      state.value = "DNI";
    },
    menuRedirect: (state) => {
      state.value = "MENU";
    },
    ticketRedirect: (state) => {
      state.value = "TICKET";
    },
    ticketListRedirect: (state) => {
      state.value = "TICKETLIST";
    },
  },
});

export const { homeRedirect, dniRedirect, menuRedirect, ticketRedirect, ticketListRedirect } = appSlice.actions;

export const selectApp = (state) => state.app.value;

export default appSlice.reducer;
