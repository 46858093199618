import React, { useState } from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { menuRedirect } from "../redux/slices/appSlice";

import DniService from "../services/DniService";

import { useSelector } from "react-redux";
import { selectDni } from "../redux/slices/dniSlice";

export const DniApp = () => {
  //const [submitted, setSubmitted] = useState(false);
  const regDni = useSelector(selectDni);
  const dispatch = useDispatch();

  const baseDni = {    
    dia: "",
    mes: "",
    hora: "",
    telefono: "",
  };

  const [dni, setBaseDni] = useState(baseDni);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBaseDni({ ...dni, [name]: value });

    //console.log(name + ": " + value);
    console.log(dni);
  };  

  const registerDni = () => {    
    //DniService.getDni

    const arr1 = [dni.dia,dni.mes,dni.anio];
    let _fecha = arr1.join("/");

    const request = {
      dni: regDni.dni,
      apellidoMaterno: regDni.apellidoMaterno,
      apellidoPaterno: regDni.apellidoPaterno,
      nombres: regDni.nombres,      
      fechaNacimiento: _fecha,      
      sexo: regDni.sexo,
      telefono: dni.telefono,
    }

    console.log(request);
    
    if(dni.dia<=0 || dni.dia >31){
      return;
    }
    if(dni.mes<=0 || dni.mes >12){
      return;
    }
    if(dni.anio<=1950 || dni.anio >2003){
      return;
    }

    let res = { value: request };    

    console.log(res);

    DniService.registerDni(res.value)
      .then((response) => {
        console.log(response);
        dispatch(menuRedirect());
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card className="w-100">
      <Card.Body>
        <Card.Title>
          <h2>DNI</h2>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Corrobore sus datos</Card.Subtitle>
        <Form>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              DNI
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={regDni.dni} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              Nombre
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={regDni.nombres} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              A. Paterno
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={regDni.apellidoPaterno} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              A. Materno
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={regDni.apellidoMaterno} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              F. Nacimiento
            </Form.Label>
            <Col sm="3">
              <Form.Control placeholder="DD" type="number" onChange={handleInputChange} name="dia" />
            </Col>
            <Col sm="3">
              <Form.Control placeholder="MM" type="number" onChange={handleInputChange} name="mes" />
            </Col>
            <Col sm="3">
              <Form.Control placeholder="AAAA" type="number" onChange={handleInputChange} name="anio" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="3">
              Telefono
            </Form.Label>
            <Col sm="9">
              <Form.Control placeholder="000000000" type="number" onChange={handleInputChange} name="telefono"/>
            </Col>
          </Form.Group>
        </Form>
      </Card.Body>
      <Button variant="primary" onClick={registerDni} block>
        Registrar y Continuar
      </Button>
    </Card>
  );
};
