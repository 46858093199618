import React, { useState } from "react";
import { Card, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { TicketElement } from "./Ticket.element";

import { useDispatch } from "react-redux";
import { menuRedirect } from "../redux/slices/appSlice";

import TicketService from "../services/TicketService";
import OpcionService from "../services/OpcionService";

import { useSelector } from "react-redux";
import { selectDni } from "../redux/slices/dniSlice";

import { GeneralModal } from "./Complements/General.modal";

export const TicketApp = () => {
  const regDni = useSelector(selectDni);
  const dispatch = useDispatch();

  const [apiMessage, setapiMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);

  const baseTicket = {
    terminal: "",
    monto: 0,
    dia: "",
    mes: "",
    hora: "",
    minuto: "",
    anio: "2021",
  };

  const [response, setResponse] = useState(false);
  const [ticket, setBaseTicket] = useState(baseTicket);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBaseTicket({ ...ticket, [name]: value });

    //console.log(name + ": " + value);
    console.log(ticket);
  };

  const sendRequest = () => {
    setSubmitted(true);
    if (!response) {
      sendData();
    } else {
      registerData();
    }
  };

  const returnMenu = () => {
    dispatch(menuRedirect());
  };

  const sendData = () => {
    const arr1 = [ticket.anio, ticket.mes, ticket.dia];
    const _fecha = arr1.join("-");

    const arr2 = [ticket.hora, ticket.minuto];
    const _hora = arr2.join(":");

    const _request = {
      terminal: ticket.terminal,
      monto: ticket.monto,
      fecha: _fecha,
      hora: _hora,
    };

    console.log(_request);

    TicketService.getTicketForm(_request)
      .then((res) => {
        console.log("res: " + res.data);
        if (!res.data) {
          setapiMessage("No se encontro su Ticket, intente nuevamente");
          setModalShow(true);
        }

        console.log(res.data);

        setResponse(res.data);
        setSubmitted(false);
      })
      .catch((e) => {
        console.log(e);
        setapiMessage("No se encontro su Ticket, intente nuevamente");
        setModalShow(true);
        setSubmitted(false);
      });
    //.finally((e) => setSubmitted(false));
  };

  const registerData = () => {
    const res = {
      dni: regDni.dni,
      ticket_index: response.id,
      beneficencia: response.beneficencia
    };
    console.log(res);
    OpcionService.registerOpcionForm(res)
      .then((res) => {
        console.log(res);
        //setResponse(res.data);
        setapiMessage(res.data);
        setModalShow(true);
        setSubmitted(false);
      })
      .catch((e) => {
        console.log(e);
        setapiMessage("No se pudo registrar su Ticket, intente nuevamente");
        setModalShow(true);
        setSubmitted(false);
      });
    //.finally((e) => setSubmitted(false));
  };

  const onModalHide = () => {
    setModalShow(false);
    if (response && !submitted) {
      dispatch(menuRedirect());
    }
  };

  return (
    <Card className="w-100 mt-3">
      <Card.Body>
        <Card.Title>
          <h1>Ticket</h1>
        </Card.Title>
        <Card.Subtitle>Ingrese la informacion de su ticket</Card.Subtitle>
        {!response ? (
          <Form className="mt-4 mb-4">
            <Form.Group as={Row} controlId="Teminal">
              <Form.Label column sm="2">
                Serie
              </Form.Label>
              <Col sm="4">
                <Form.Control placeholder="0000" type="number" onChange={handleInputChange} name="terminal" />
              </Col>
              <Form.Label column sm="2">
                Monto
              </Form.Label>
              <Col sm="4">
                <Form.Control placeholder="000.00" type="number" onChange={handleInputChange} name="monto" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="Fecha">
              <Form.Label column sm="2">
                Fecha
              </Form.Label>
              <Col sm="3">
                <Form.Control placeholder="DD" type="number" onChange={handleInputChange} name="dia" />
              </Col>
              <Col sm="3">
                <Form.Control placeholder="MM" type="number" onChange={handleInputChange} name="mes" />
              </Col>
              <Col sm="3">
                <Form.Control placeholder="AAAA" type="number" onChange={handleInputChange} name="anio" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="Hora">
              <Form.Label column sm="2">
                Hora
              </Form.Label>
              <Col sm="4">
                <Form.Control placeholder="HH" type="number" onChange={handleInputChange} name="hora" />
              </Col>
              <Col sm="4">
                <Form.Control placeholder="MM" type="number" onChange={handleInputChange} name="minuto" />
              </Col>
            </Form.Group>
          </Form>
        ) : (
          <TicketElement
            {...response}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
        {submitted ? (
          <Button variant="primary" block disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </Button>
        ) : (
          <Button variant="primary" onClick={sendRequest} block>
            <strong> {!response ? "BUSCAR" : "REGISTRAR"}</strong>
          </Button>
        )}

        <Button variant="primary" onClick={returnMenu} block>
          <strong>REGRESAR</strong>
        </Button>
      </Card.Body>
      <GeneralModal show={modalShow} onHide={onModalHide} body={apiMessage} />
    </Card>
  );
};
