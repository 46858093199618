import React, { useState } from "react";
import { Badge, Row, Col, Container } from "react-bootstrap";

export const OpcionElement = (props) => {
  const [Opcion] = useState(props);
  //const [Numero] = useState(index);
  return (
    <Container>      
      <br/>
      <Row className="">
        <Col md="4">
          <Badge variant="info">SEDE:</Badge>
        </Col>
        <Col md="auto">          
          <Badge>{Opcion.ciudad}</Badge>
        </Col>
      </Row>
      <Row className="">
        <Col md="4">
          <Badge variant="info">FECHA:</Badge>
        </Col>
        <Col md="auto">
          <Badge>{Opcion.fecha}</Badge>
        </Col>
      </Row>
      <Row className="">
        <Col md="4">
          <Badge variant="info">TK:</Badge>
        </Col>
        <Col md="auto">
          <Badge>{Opcion.tk}</Badge>
        </Col>
      </Row>
      <Row className="">
        <Col md="4">
          <Badge variant="info">PUNTO DE VENTA:</Badge>
        </Col>
        <Col md="auto">
          <Badge>{Opcion.concesionario}</Badge>
        </Col>
      </Row>
      <Row className="">
        <Col md="4">
          <Badge variant="info">MONTO:</Badge>
        </Col>
        <Col md="auto">
          <Badge>{Opcion.monto}</Badge>
        </Col>
      </Row>
      <Row className="">
        <Col md="4">
          <Badge variant="info">SERIE:</Badge>
        </Col>
        <Col md="auto">
          <Badge>{Opcion.serie}</Badge>
        </Col>
      </Row>
      <br/>
    </Container>
  );
};
