import React, { useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { dniRedirect, menuRedirect } from "../redux/slices/appSlice";
import { registerDni } from "../redux/slices/dniSlice";
import promoimg from "../Assets/Adelanta_grati_2024.png";

import DniService from "../services/DniService";

import { GeneralModal } from "./Complements/General.modal";

export const HomeApp = () => {
  const [dni, setDni] = useState("");

  const [apiMessage, setapiMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setDni(value);
  };
  const sendDni = () => {
    console.log(dni);
    setSubmitted(true);

    DniService.getDni(dni)
      .then((response) => {
        let pObject = response.data.result;
        dispatch(registerDni(pObject));
        console.log(pObject);
        if (response.data.reference === "FND") {
          dispatch(dniRedirect());
        }
        if (response.data.reference === "REG") {
          dispatch(menuRedirect());
        }
      })
      .catch((e) => {
        console.log(e);
        setapiMessage("No se encontro su DNI, intente nuevamente");
        setModalShow(true);
        setSubmitted(false);
      });
    //.finally((e) => setSubmitted(false));
  };

  return (
    <Card className="w-100">
      <Card.Img variant="top" src={promoimg} />
      <Card.Body>
        <Card.Title>
          <h2>Registro de Premios</h2>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Escriba su DNI para identificarse</Card.Subtitle>
        <input
          type="number"
          className="form-control"
          id="title"
          required
          placeholder="DNI"
          value={dni}
          onChange={handleInputChange}
          name="title"
        />
      </Card.Body>
      {submitted ? (
        <Button variant="primary" block disabled>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        </Button>
      ) : (
        <Button variant="primary" onClick={sendDni} block>
          Ingresar
        </Button>
      )}
      <GeneralModal show={modalShow} onHide={() => setModalShow(false)} body={apiMessage} />
    </Card>
  );
};

//export default HomeApp;
