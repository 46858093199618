import React from "react";
//import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import promoimg from "../Assets/Adelanta_grati_2024.png";

import { useSelector } from "react-redux";
import { selectDni } from "../redux/slices/dniSlice";

import { useDispatch } from "react-redux";
import { ticketRedirect, homeRedirect, ticketListRedirect } from "../redux/slices/appSlice";

export const MenuApp = () => {
  const regDni = useSelector(selectDni);

  const dispatch = useDispatch();

  const registerTicket = () => {
    dispatch(ticketRedirect());
  };

  const getTicketList = () => {
    dispatch(ticketListRedirect());
  };

  const returnHome = () => {
    dispatch(homeRedirect());
  };

  return (
    <Card className="w-100">
      <Card.Img variant="top" src={promoimg} />
      <Card.Body>
        <Card.Title>
          <h1>Bienvenido</h1>
        </Card.Title>
        <Card.Subtitle className="mb-4 text-muted">{regDni.nombres + " " + regDni.apellidoPaterno + " " + regDni.apellidoMaterno}</Card.Subtitle>

        <Button block variant="primary" onClick={registerTicket}>
          <strong>REGISTRAR PREMIO</strong>
        </Button>
        <Button block variant="primary" onClick={getTicketList}>
          <strong>REVISAR TICKETS REGISTRADOS</strong>
        </Button>
        <Button block variant="primary" onClick={returnHome}>
          <strong>SALIR</strong>
        </Button>
      </Card.Body>
    </Card>
  );
};
