import { configureStore } from "@reduxjs/toolkit";
import dniReducer from "./slices/dniSlice";
import appReducer from "./slices/appSlice";

export default configureStore({
  reducer: {
    dni: dniReducer,
    app: appReducer,
  },
});
